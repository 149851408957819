import {Menu, MenuButton, MenuItems, MenuItem, MenuSeparator} from '@headlessui/react';
import clsx from 'clsx';
import type {BaseComponentProps} from '@/components/types';
import type {ComponentProps, FC, MouseEventHandler} from 'react';
import LivanLink from '@/components/core/LivanLink';

import type {LucideIcon} from 'lucide-react';
import {LivanTooltip} from '@/components/core/LivanTooltip';
import React, {useId} from 'react';
import LoadingIndicator from '@/components/core/LoadingIndicator';
import {observer} from 'mobx-react-lite';

export type DropdownMenuSection = {
  id: string | number;
  items: DropdownMenuItem[];
} & XOR<
  {
    Component: FC<any>;
    componentProps: Record<string, unknown>;
  },
  {
    label: string;
  },
  object
>;

export type DropdownMenuItem = {
  styleType?: StyleType;
  disabled?: boolean;
  disabledTooltip?: string;
} & XOR<
  {
    to: string;
    onClick: MouseEventHandler<HTMLAnchorElement>;
  },
  {
    to: string;
  },
  {
    onClick: MouseEventHandler<HTMLAnchorElement>;
  }
> &
  XOR<
    {
      id: string | number;
      Icon?: LucideIcon | null;
      Component: FC<any>;
      componentProps: Record<string, unknown>;
    },
    {
      label: string;
      Icon: LucideIcon | null;
    }
  >;

type Props = BaseComponentProps & {
  buttonClassName?: string;
  anchor?: ComponentProps<typeof MenuItems>['anchor'];
  truncate?: boolean;
  buttonRole?: ComponentProps<typeof MenuButton>['role'];
  fullWidth?: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
  loadingMenuItems?: boolean;
} & XOR<
    {
      items: DropdownMenuItem[];
    },
    {
      sections: DropdownMenuSection[];
    }
  >;
const DropdownMenu: FC<Props> = observer(function DropdownMenu(props) {
  const {
    className,
    buttonClassName,
    items,
    children,
    anchor,
    truncate,
    buttonRole,
    fullWidth,
    disabledTooltip,
    loadingMenuItems,
  } = props;

  let disabled = false;
  if (props.sections) {
    disabled = !props.sections.length;
  } else if (items) {
    disabled = !items.length;
  }

  const sections = props.sections || ([{id: 'default', items}] as DropdownMenuSection[]);

  const outsideClickScope = useId();

  return (
    <Menu
      as="div"
      className={clsx(className, 'DropdownMenu relative')}
      outsideClickScope={outsideClickScope}
    >
      <MenuButton
        role={buttonRole}
        className={clsx(
          buttonClassName,
          truncate && 'truncate',
          fullWidth && 'w-full',
          'flex items-center',
          disabled && 'cursor-not-allowed',
        )}
        disabled={disabled}
        aria-haspopup="menu"
        aria-controls="dropdown-menu"
      >
        {disabled && disabledTooltip ? (
          <LivanTooltip content={disabledTooltip}>{children}</LivanTooltip>
        ) : (
          children
        )}
      </MenuButton>
      <MenuItems
        id="dropdown-menu"
        transition
        className="absolute right-0 z-30 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in flex flex-col divide-y"
        anchor={anchor}
      >
        {loadingMenuItems ? (
          <div className="flex justify-center items-center w-full my-2">
            <LoadingIndicator color="black" />
          </div>
        ) : (
          sections.map((section, index) => {
            const {items, label: sectionLabel, Component, componentProps} = section;
            return (
              <div key={section.id}>
                {(Component || sectionLabel) && (
                  <div className="flex items-center px-4 py-2">
                    {Component ? <Component {...componentProps} /> : <h5>{sectionLabel}</h5>}
                  </div>
                )}
                {items.length ? (
                  items.map((item, i) => {
                    const {label: itemLabel, onClick, to, disabled, disabledTooltip, id} = item;
                    const Content = (
                      <LivanLink
                        role="menuitem"
                        to={to}
                        type="unstyled"
                        disabled={disabled}
                        className={clsx(
                          'px-4 py-2  flex items-center gap-3 truncate',
                          !disabled && 'data-[focus]:bg-gray-100',
                          disabled && 'opacity-75',
                          item.styleType === 'destructive' && 'text-red-500',
                          item.styleType === 'warning' && 'text-yellow-500',
                        )}
                        onClick={onClick}
                        prefetch="viewport"
                      >
                        {item.Icon && (
                          <item.Icon
                            size={16}
                            color={
                              item.styleType === 'destructive'
                                ? 'red'
                                : item.styleType === 'warning'
                                  ? 'yellow'
                                  : 'black'
                            }
                            className="shrink-0"
                          />
                        )}
                        {item.Component ? <item.Component {...item.componentProps} /> : itemLabel}
                      </LivanLink>
                    );
                    return (
                      <MenuItem key={id != null ? id : itemLabel}>
                        {disabled && disabledTooltip ? (
                          <LivanTooltip
                            content={disabledTooltip}
                            anchor="bottom start"
                          >
                            {Content}
                          </LivanTooltip>
                        ) : (
                          Content
                        )}
                      </MenuItem>
                    );
                  })
                ) : (
                  <div className="px-4 py-2 text-sm text-gray-500 cursor-not-allowed">
                    No actions available
                  </div>
                )}
              </div>
            );
          })
        )}
      </MenuItems>
    </Menu>
  );
});

export default DropdownMenu;
